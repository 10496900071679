import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ITheme, ThemesDataAccessService} from './themes-data-access.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);
  public readonly ready$: Observable<boolean> = this._ready$.asObservable();
  private readonly _productId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public readonly productId$: Observable<string> = this._productId$.asObservable();
  private readonly _themeName$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public readonly themeName$: Observable<string> = this._themeName$.asObservable();
  private _theme$: ReplaySubject<ITheme> = new ReplaySubject<ITheme>(1);
  public readonly theme$: Observable<ITheme> = this._theme$.asObservable();

  constructor(router: Router, private _themesDataAccessService: ThemesDataAccessService) {
    router.events
      .pipe(filter((e) => e instanceof ActivationStart))
      .subscribe((e: ActivationStart) => {
        // TypeScript type guard
        if (!this._themeName$.isStopped) {
          const queryParams = e.snapshot.queryParamMap;
          const productId = queryParams.get('productId');
          this._productId$.next(productId);
          this._productId$.complete();
          let theme = queryParams.get('theme');
          if (!!theme) {
            this._themeName$.next(theme);
          } else if (!!productId) {
            // For now this is static until there is more demand for white-labeled sites.
            const productLower = productId.toLowerCase();
            if (productLower === 'pdx-mastercard') {
              theme = 'mastercard';
            }
          }
          theme = theme || 'default';
          this._themeName$.next(theme);
          this._themeName$.complete();
        }
      });
    this._themeName$.subscribe((themeName) => this._getTheme(themeName));
  }

  private _getTheme(themeName: string): void {
    if (!this._theme$.isStopped) {
      this._themesDataAccessService.getTheme(themeName).subscribe((theme) => {
        this._theme$.next(theme);
        this._theme$.complete();
        this._ready$.next(true);
        this._ready$.complete();
      });
    }
  }
}
