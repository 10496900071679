import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { Constants } from '../constants';

/**
 * @class SplashScreenService service
 * @see https://angular.io/api/core/Injectable
 */
@Injectable({ providedIn: 'root' })
export class SplashScreenService {

    private dataLoaded = new BehaviorSubject(false);
    loaderObserver$ = this.dataLoaded.asObservable();

    constructor() {
        const pathname = window.location.pathname;

        if (pathname === Constants.ROOT_URI + Constants.RECLAIM_ACCOUNT_SUCCESS_ROUTE
            || pathname === Constants.ROOT_URI + Constants.RESET_PASSWORD_SUCCESS_ROUTE
            || pathname === Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
            this.dataLoadedHandler(true);
        }
    }

    dataLoadedHandler(loaded: boolean) {
        this.dataLoaded.next(loaded);
    }
}
