import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {Constants} from '../shared/constants';
import {Utils} from '../shared/utils';

export interface ITheme {
  enterpriseKey: string;
  logoUrl: string;
  logoWidth: number;
  logoHeight: number;
  /**
   * The login logo height in pixels.
   */
  logoLoginHeight: string;
  logoUrlLogin: string;
  footerLogoUrl: string;
  footerLogoAnchorUrl: string;
  copyrightHolder: string;
}

const resourceBasePath = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION + '/themes';

@Injectable({
  providedIn: 'root'
})
export class ThemesDataAccessService {
  private _theme: Observable<ITheme>;

  constructor(private _http: HttpClient) {
  }

  public getTheme(themeName: string = 'default'): Observable<ITheme> {
    if (!this._theme) {
      this._theme = this._http.get<ITheme>(`${resourceBasePath}/${themeName}`).pipe(shareReplay(1));
    }
    return this._theme;
  }
}
