import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import 'url-search-params-polyfill';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LogoutComponent} from './logout/logout.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HttpInterceptor} from './shared/http/http.interceptor';
import {LocaleService} from './shared/services/locale.service';
import {OriginValidatorService} from './shared/services/origin-validator.service';
import {ProductCatalogService} from './shared/services/product-catalog.service';
import {SessionService} from './shared/services/session.service';
import {TrustGuard} from './shared/services/trust-guard.service';
import {SharedModule} from './shared/shared.module';
import {SplashScreenComponent} from './shared/splash-screen/splash-screen.component';
import {ValidateTokenService} from './shared/validate-token.service';
import {ThemesModule} from './themes/themes.module';

/**
 * @function HttpLoaderFactory
 * AoT requires an exported function for factories
 * @param {HttpClient} http to load i18n internationalization files
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LogoutComponent,
    SplashScreenComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    ThemesModule,
  ],
  providers: [
    LocaleService,
    ProductCatalogService,
    SessionService,
    // OktaService,
    OriginValidatorService,
    TrustGuard,
    CookieService,
    ValidateTokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
/**
 * @class AppModule Module class for SaaS Login UI
 * @see https://angular.io/guide/ngmodules
 */
export class AppModule {
}
