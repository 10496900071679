import {Component, HostBinding} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {ThemeService} from '../../themes/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @HostBinding('class.site-footer') true;

  public logoUrl: SafeResourceUrl;
  public logoAnchorUrl: SafeUrl;
  public copyrightHolder: SafeHtml;

  constructor(themeService: ThemeService, sanitizer: DomSanitizer) {
    themeService.theme$.subscribe((theme) => {
      if (theme.footerLogoUrl) {
        this.logoUrl = sanitizer.bypassSecurityTrustResourceUrl(theme.footerLogoUrl);
      }
      this.logoAnchorUrl = !!theme.footerLogoAnchorUrl ?
        sanitizer.bypassSecurityTrustUrl(theme.footerLogoAnchorUrl) :
        sanitizer.bypassSecurityTrustUrl('http://www.precisely.com');
      this.copyrightHolder = !!theme.copyrightHolder ?
        sanitizer.bypassSecurityTrustHtml(theme.copyrightHolder) :
        sanitizer.bypassSecurityTrustHtml(FooterComponent._getCopyrightString());
    });
  }

  private static _getCopyrightString(): string {
    const startYear = 2015;
    const currentYear: number = new Date().getFullYear();
    const date = 2015 === currentYear ? `${currentYear}` : `${startYear},${currentYear}`;
    return `&copy;${date} Precisely. All rights reserved.`;
  }
}
