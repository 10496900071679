import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';

import {throwError} from 'rxjs';
import {Observable} from 'rxjs/Rx';
import {v4 as uuid} from 'uuid';

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {AppError} from '../error/app.error';
import {Utils} from '../utils';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  constructor() {
  }

  private static onFinally(): void {
    document.body.style.cursor = 'default';
  }

  private static getTransactionId(): string {
    const transactId = uuid() + '-' + Utils.getCurrentESTDateTimeInISOFormat();
    Utils.setTransactionId(transactId);
    return transactId;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Clone the request to add the new header.
    const requestId = HttpInterceptor.getTransactionId();
    const authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-PB-Locale': Utils.getCurrentLocale(),
        'Accept': 'application/json, text/plain, */*',
        'X-PB-TransactionId': requestId,
        'X-Request-Id': requestId
      }),
      withCredentials: true,
    });

    document.body.style.cursor = 'wait';
    // send the newly created request
    return next.handle(authReq).map((response) => {
      HttpInterceptor.onFinally();
      return response;
    }).catch(this.handleError);
  }

  private handleError = (error: Response) => {
    HttpInterceptor.onFinally();
    let message = 'Something went Wrong!';
    if (error instanceof HttpErrorResponse) {
      switch (error["status"]) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 403:
          message = 'Authentication Failed';
          break;
        case 408:
          message = 'Session Expired';
          break;
        case 401:
          message = 'Unauthorized';
          break;
        case 400:
          message = 'Bad Request';
          break;
      } // end switch
    }
    return throwError(new AppError(this, error, message));
  }

}
