import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {Constants} from '../constants';
import {Utils} from '../utils';

/**
 * @class SessionService service class to read cookies and session details from login-api
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class SessionService {
  constructor(private http: HttpClient) {
  }

  /**
   * @function getSessionDetails function to Call backend service to get cookies and session details
   * @see https://angular.io/guide/observables
   * @see https://www.typescriptlang.org/docs/handbook/basic-types.html
   * @return Observable<any> | never
   */
  getSessionDetails(): Observable<any> | never {
    const endpoint =
      Utils.getApiUrl() +
      Constants.LOGIN_SERVICES_API +
      Constants.API_VERSION +
      Constants.SESSION_DOMAIN;
    // TODO - add type
    return this.http
      .post<any>(endpoint, {});
  }
}
