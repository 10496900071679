import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import {of} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {flatMap, shareReplay} from 'rxjs/operators';
import {ThemeService} from '../../themes/theme.service';
import {Constants} from '../constants';
import {Utils} from '../utils';

export interface ISupportInfo {
  code: 'default';
  country: string;
  phone: string;
  chatId: string;
  hours: string;
  // TODO verify type
  chatAvailability: string;
}

export interface IProductCatalogEntry {
  productInfo: {
    product: {
      description: string;
    };
  };
  supportInfo: ISupportInfo[];
  homePageUrl: string;
  desktopApp: boolean;
  productId: string;
  formatVersion: string;
  /**
   * multi-factor authentication enabled
   */
  mfa: boolean;
  rememberMe: boolean;
  title: string;
  signupPageUrl: string;
  allowLanguageChange: boolean;
}

/**
 * @class ProductCatalogService service class to product catalog values
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class ProductCatalogService {
  // TODO - Should not be using static classes/methods.  Cannot be injected for unit testing.
  private readonly _endpointBase = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
    Constants.PRODUCT_DOMAIN + Constants.ROOT_URI;
  private _catalogEntry: Observable<IProductCatalogEntry>;

  constructor(private http: HttpClient, private _themeService: ThemeService) {
  }

  /**
   * Calls backend service to get product catalog from productId query param
   *
   * In the case of logout, we do not get a productId (currently).
   * We return null so that the application goes to a ready state.
   * Where getProduct() is used the code must check for a null return value.
   */
  public getProduct(): Observable<IProductCatalogEntry | null> {
    if (!this._catalogEntry) {
      this._catalogEntry = this._themeService.productId$.pipe(flatMap((productId) => {

          if (!productId) {
            return of(null);
          }
          const endpoint = this._endpointBase + productId;
          return this.http.get<IProductCatalogEntry>(endpoint).pipe(shareReplay(1));
        },
      ), shareReplay(1));
    }
    return this._catalogEntry;
  }

}
