import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MoveToHeadDirective} from './directives/move-to-head.directive';
import {HeaderComponent} from './header/header.component';
import { HeadingLogoComponent } from './heading-logo/heading-logo.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [HeaderComponent, MoveToHeadDirective, HeadingLogoComponent],
  providers: [],
    exports: [FormsModule, CommonModule, ReactiveFormsModule, TranslateModule, MoveToHeadDirective, HeadingLogoComponent],
})
export class SharedModule {

  constructor(private translate: TranslateService) {
  }
}
