import {Component, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Observable, ReplaySubject} from 'rxjs';
import {ThemeService} from '../../themes/theme.service';
import {Constants} from '../constants';
import {Utils} from '../utils';
import {SplashScreenService} from './splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplashScreenComponent {
  public logoUrl: SafeResourceUrl;
  dataLoaded$: Observable<boolean>;
  theme;
  interstitial = false;
  private _ready$ = new ReplaySubject<boolean>(1);
  public isReady$: Observable<boolean> = this._ready$.asObservable();

  constructor(private splashScreenService: SplashScreenService, themeService: ThemeService, sanitizer: DomSanitizer
  ) {
    const pathname = window.location.pathname;
    if (pathname === Constants.ROOT_URI + Constants.LOADING_ROUTE) {
      this.interstitial = true;
      document.body.setAttribute('theme', 'none');
      this._ready$.next(true);
      this._ready$.complete();
    } else {
      // change the theme if URL has a theme parameter
      this.theme = Utils.getParam('theme') || 'classic';
    }
    themeService.theme$.subscribe((theme) => {
      if (theme.footerLogoUrl) {
        this.logoUrl = sanitizer.bypassSecurityTrustResourceUrl(theme.footerLogoUrl);
        this._ready$.next(true);
        this._ready$.complete();
      }
    });
    this.dataLoaded$ = this.splashScreenService.loaderObserver$;
  }

}
