import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {map, tap} from 'rxjs/operators';

import {ReplaySubject} from 'rxjs';
import {Constants} from './shared/constants';
import {LocaleService} from './shared/services/locale.service';
import {ProductCatalogService} from './shared/services/product-catalog.service';
import {TrustGuard} from './shared/services/trust-guard.service';
import {SplashScreenService} from './shared/splash-screen/splash-screen.service';
import {Utils} from './shared/utils';
import {ThemeService} from './themes/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

/**
 * @class AppComponent Base component class
 * @see https://angular.io/guide/bootstrapping
 */
export class AppComponent implements AfterViewInit, OnInit {
  title = 'SaaS Login UI';
  originValidated$: Observable<boolean>;
  dataLoaded = false;

  public readonly themeCssUrl$: Observable<SafeStyle>;
  private _ready$ = new ReplaySubject<boolean>(1);
  public readonly isReady$: Observable<boolean> = this._ready$.asObservable();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private localeService: LocaleService,
    private splashScreenService: SplashScreenService,
    private trustGuard: TrustGuard,
    themeService: ThemeService,
    sanitizer: DomSanitizer,
    titleService: Title,
    productCatalogService: ProductCatalogService,
    router: Router
  ) {
    /** support for QTWeb */
    const pathname = window.location.pathname;
    if (Utils.unusualBrowser() && pathname !== Constants.ROOT_URI + Constants.LOGOUT_ROUTE
      && pathname !== Constants.ROOT_URI + Constants.ERROR_ROUTE) {
      // not yet supported to handle various themes, for now we default to classic theme styles
      document.body.setAttribute('style', 'background-image: -webkit-linear-gradient(left, #3e53a4 0%, #cf0989 100%)');
      return;
    }
    document.body.setAttribute('theme', 'classic');
    this.themeCssUrl$ = themeService.themeName$
      .pipe(map((themeName) => {
        // For now there is no default theme css so don't load it.
        if (!!themeName && themeName !== 'default') {
          return sanitizer.bypassSecurityTrustResourceUrl(`/assets/css/themes/${themeName}.css`);
        }
      }));
    // We load when the themeName is known, which is enough to know the css
    productCatalogService.getProduct().subscribe((product) => {
      let title: string;
      title = (product && product.title) ? product.title : 'Precisely';
      titleService.setTitle(title);
      themeService.themeName$.subscribe(() => {
        this._ready$.next(true);
        this._ready$.complete();
      });
    });
    router.initialNavigation();
  }

  ngOnInit() {
    this.originValidated$ = this.trustGuard.originValidated;
  }

  ngAfterViewInit() {
    // We don't ready until this point so that the move-to-head directive in app.html can execute first.
    if (window.location.pathname !== Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
      this.splashScreenService.loaderObserver$.pipe((
        tap((dataLoaded) => {
          if (dataLoaded !== this.dataLoaded) {
            this.dataLoaded = dataLoaded;
            this.changeDetectorRef.detectChanges();
          }
        })
      )).subscribe();
    }
  }

}
