import 'url-search-params-polyfill';

import {Component, HostBinding, OnInit} from '@angular/core';

import {Constants} from '../shared/constants';
import {ConfigService} from '../shared/services/config.service';
import {Utils} from '../shared/utils';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  @HostBinding('class.signin')
  private _isSignInClassEnabled = true;

  constructor(private configService: ConfigService) {
    this.loadConfig();
  }

  private static redirectToLoginPage() {
    const redirectUrl = Utils.getParam('redirect') || Constants.DEFAULT_LANDING_PAGE;
    window.location.href = decodeURIComponent(redirectUrl);
  }

  private loadConfig() {
    this.configService.getConfig().subscribe((config) => {
      this.removeOktaSession(config);
    }, (_err) => {
      LogoutComponent.redirectToLoginPage();
    });
  }

  private removeOktaSession(config: any) {
    const url = config.oktaUrl + Constants.OKTA_SESSION_URI;
    try {
      const xhr = new XMLHttpRequest();
      xhr.onerror = () => {
        console.log(`Invalid URL or Cross-Origin Request Blocked.
        You must explicitly add this site ${window.location.origin} to the list of allowed websites in your Okta Admin Dashboard`);
        LogoutComponent.redirectToLoginPage();
      };
      xhr.onload = () => LogoutComponent.redirectToLoginPage();
      xhr.open('DELETE', url, false);
      xhr.setRequestHeader('Content-type', 'application/json');
      if ('withCredentials' in xhr) {
        xhr.withCredentials = true;
      }
      xhr.send();
    } catch (err) {
      LogoutComponent.redirectToLoginPage();
    }
  }

}
