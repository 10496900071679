import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, Renderer2 } from '@angular/core';

/**
 * Moves an element to the head of the HTML document.
 *
 * Allows data binding something like  a link or meta element and then moving it into the head element.
 * @see {@link https://github.com/angular/angular/issues/15776#issuecomment-389409791|GitHub issue discussion}
 */
@Directive({
  selector: '[appMoveToHead]',
})
export class MoveToHeadDirective implements OnDestroy, AfterViewInit {
  constructor(private renderer: Renderer2, private elRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit(): void {
    // After view init is required for favIcons in firefox.
    // during onInit, the href of the element is a blank value.  While the attribute later gets updated,
    // firefox will not update the favicon based on an href change alone.  It has to see an entire element change.
    this.renderer.appendChild(this.document.head, this.elRef.nativeElement);
    this.renderer.removeAttribute(this.elRef.nativeElement, 'appmovetohead');
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.document.head, this.elRef.nativeElement);
  }
}
