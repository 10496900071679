import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LogoutComponent } from "./logout/logout.component";
import { Constants } from "./shared/constants";
import { FooterComponent } from "./shared/footer/footer.component";
import { TrustGuard } from "./shared/services/trust-guard.service";
import { SplashScreenComponent } from "./shared/splash-screen/splash-screen.component";

/**
 * Define list of routes used in theLOGIN_ROUTE
 * @return {Routes} navigation route
 */

const LOGIN_ROUTE =
  "/" +
  Constants.LOGIN_ROUTE +
  "?" +
  Constants.PRODUCT_ID_PARAM +
  "=" +
  Constants.APP_NAME +
  "&" +
  Constants.TARGET_RESOURCE_PARAM +
  "=" +
  Constants.DEFAULT_LANDING_PAGE;
const routes: Routes = [
  // not using constants for routes as compodoc is not supported with global variables
  { path: "", redirectTo: LOGIN_ROUTE, pathMatch: "full" },

  { path: "loading", component: SplashScreenComponent },
  {
    path: "login-deprecate",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
    canActivate: [TrustGuard],
  },
  {
    path: "logout-deprecate",
    component: LogoutComponent,
    canActivate: [TrustGuard],
  },

  {
    path: "forgot-password-deprecate",
    loadChildren: () =>
      import("./forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
    canActivate: [TrustGuard],
  },
  {
    path: "forgot-password/success-deprecate",
    loadChildren: () =>
      import("./forgot-password/success/forgot-password-success.module").then(
        (m) => m.ForgotPasswordSuccessModule
      ),
  },

  {
    path: "reset-password-deprecate",
    loadChildren: () =>
      import("./reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [TrustGuard],
  },
  {
    path: "reset-password/success-deprecate",
    loadChildren: () =>
      import("./reset-password/success/reset-password-success.module").then(
        (m) => m.ResetPasswordSuccessModule
      ),
  },

  {
    path: "claim-account-deprecate",
    loadChildren: () =>
      import("./claim-account/claim-account.module").then(
        (m) => m.ClaimAccountModule
      ),
    canActivate: [TrustGuard],
  },
  {
    path: "reclaim-account-deprecate",
    loadChildren: () =>
      import("./reclaim-account/reclaim-account.module").then(
        (m) => m.ReclaimAccountModule
      ),
    canActivate: [TrustGuard],
  },
  {
    path: "reclaim-account/success-deprecate",
    loadChildren: () =>
      import("./reclaim-account/success/reclaim-account-success.module").then(
        (m) => m.ReclaimAccountSuccessModule
      ),
  },

  {
    path: "error",
    loadChildren: () =>
      import("./shared/error/error.module").then((m) => m.ErrorModule),
  },

  { path: "footer", component: FooterComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: "disabled",
    }),
  ],
  exports: [RouterModule],
})

/**
 * @class AppRoutingModule Routing module class to route to different URLs
 * @see https://angular.io/guide/router
 */
export class AppRoutingModule {}
