import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {Constants} from '../constants';
import {Utils} from '../utils';

/**
 * @class ConfigService service class to get config values
 * @see https://angular.io/api/core/Injectable
 */
@Injectable({providedIn: 'root'})
export class ConfigService {
  // TODO - Add type
  private _configData: Observable<any>;

  constructor(private http: HttpClient) {
  }

  getConfig(): Observable<any> {
    if (!this._configData) {
      const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
        Constants.CONFIG_DOMAIN;
      this._configData = this.http
        .get<any>(endpoint, {params: {q: 'all'}});
    }
    return this._configData;
  }

}
