import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

import {Constants} from '../constants';
import {LocalStorageWrapper} from '../local-storage-wrapper';
import {Utils} from '../utils';
import {ConfigService} from './config.service';

/**
 * @class LocaleService service class to determine locale to translate application content
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class LocaleService {
  myLocalStorage;

  constructor(
    private translate: TranslateService,
    private configService: ConfigService,
    private cookieService: CookieService) {

    translate.setDefaultLang(Constants.DEFAULT_LOCALE);
    try {
      if (typeof window.localStorage === 'undefined' || localStorage === null) {
        // support for qtweb
        if (Utils.isCookieEnabled() && cookieService) {
          this.myLocalStorage = new LocalStorageWrapper(this.cookieService);
        } else {
          // no localStorage supported, use default locale
          this.setLocale(Constants.DEFAULT_LOCALE);
          return;
        }
      } else {
        this.myLocalStorage = localStorage;
      }
      // simply return with user selected language if set
      if (this.myLocalStorage.getItem(Constants.USER_SELECTED_LOCALE)
        && this.myLocalStorage.getItem(Constants.USER_SELECTED_LOCALE) !== 'null') {
        // add supported languages in ngx-translate
        this.translate.addLangs(Utils.getLocalCodes());
        // set language only if we support them
        this.useTranslation(Utils.getCurrentLocale());
        return;
      }
      // set default language to en-us
      translate.setDefaultLang(Constants.DEFAULT_LOCALE);
      configService.getConfig().subscribe((res) => {
        let browserLang = res.browserLanguage;
        browserLang = browserLang && browserLang.split(',')[0] ? browserLang.split(',')[0].toLowerCase() : null;
        this.determineLanguage(browserLang);
      }, (_err) => {
        this.determineLanguage(null);
      });
      // determine the language

    } catch (e) {
      // no localStorage supported, use default locale
      this.setLocale(Constants.DEFAULT_LOCALE);
    }
  }

  /**
   * @function parseLocaleCode
   * @description function to set parse localeCode if it contains '-' or '_'
   * @param {string} localeCode
   */
  parseLocaleCode(localeCode: string): string {
    localeCode = localeCode.replace('_', '-');
    if (localeCode.indexOf('-') < 0) {
      return localeCode;
    }
    const localeSplit = localeCode.split('-');
    if (!localeSplit[0] || !localeSplit[1]) {
      return localeCode;
    } else if (localeSplit[0] === localeSplit[1].toLocaleLowerCase()) {
      localeCode = localeSplit[0];
    } else {
      localeCode = localeSplit[0] + '-' + localeSplit[1].toLocaleLowerCase();
    }

    return localeCode;
  }

  /**
   * @function setLocale
   * @description function to set locale and use the translation with that locale
   * @param {string} locale
   */
  public setLocale(locale: string): void {
    // set in local storage so that the selected language is preserved for future login's
    locale = locale ? locale.toLowerCase() : null;
    try {
      this.myLocalStorage.setItem(Constants.USER_SELECTED_LOCALE, locale);
    } catch (e) {
      // no localStorage supported
    }
    this.useTranslation(locale);
  }

  /**
   * @private @function determineLanguage
   * @description function to determine locale
   * Precedence 1 = high, 5 = low.
   * Precedence 1: User selected language from Sign-in page drop down
   * Precedence 2: Query parameter language from URL
   * Precedence 3: Browser language. (doesn't work for QTWeb in Login v1 and Login v2)
   * For IE/Edge, Browser language is set through control panel
   * Precedence 4: Defaults to `en-us`
   */
  private determineLanguage(browserLang): void {

    this.myLocalStorage.removeItem(Constants.QUERY_PARAM_LOCALE);
    this.myLocalStorage.removeItem(Constants.BROWSER_LOCALE);
    // check if language is set from query param "eg: https://login...com?locale=de"
    let queryParamLang = Utils.getParam('locale');
    if (queryParamLang) {
      queryParamLang = this.parseLocaleCode(queryParamLang);
      this.myLocalStorage.setItem(Constants.QUERY_PARAM_LOCALE, queryParamLang.toLowerCase());
    } else if (browserLang) {
      browserLang = this.parseLocaleCode(browserLang);
      this.myLocalStorage.setItem(Constants.BROWSER_LOCALE, browserLang.toLowerCase());
    }
    // add supported languages in ngx-translate
    this.translate.addLangs(Utils.getLocalCodes());
    // set language only if we support them
    this.useTranslation(Utils.getCurrentLocale());
  }

  /**
   * @private @function useTranslation
   * @description function that sets locale in translate object
   * @param {string} locale
   */
  private useTranslation(locale: string): void {
    if (this.translate.getLangs().indexOf(locale) === -1) {
      // set error logger as language not supported
      locale = Constants.DEFAULT_LOCALE;
    }
    this.translate.use(locale);
  }

}
