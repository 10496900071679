<div *ngIf="!(dataLoaded$|async) && (isReady$|async)"
     class="loading-screen" role="main">
  <h1 class="sr-only">Loading application</h1>
  <div aria-hidden="true"
       class="loading-screen-logo">

    <!-- changed for syncsort -->
    <svg *ngIf="!logoUrl" height="27px" viewBox="0 0 150 45" width="90px" xmlns="http://www.w3.org/2000/svg">
      <path d="M123.12,1h4.71v28.31h-4.71V1z M57.6,20.28c0,0.5-0.04,1.03-0.11,1.49H43.64c0.54,2.22,2.33,3.79,5.05,3.79
	c1.64,0,3.4-0.61,4.48-2.03l3.21,2.56c-1.65,2.33-4.55,3.71-7.65,3.71c-5.47,0-9.68-4.09-9.68-9.41c0-5.2,3.63-9.64,9.41-9.64
	C53.81,10.76,57.6,15.12,57.6,20.28z M52.86,18.29c-0.27-1.84-2.18-3.25-4.44-3.25c-2.18,0-3.94,1.3-4.63,3.25H52.86z M91.48,16.19
	c0-0.46,0.31-1.15,1.87-1.15c0.99,0,2.49,0.27,4.09,1.38l2.49-3.37c-2.07-1.91-5.01-2.3-6.62-2.3c-4.06,0-6.43,2.49-6.43,5.81
	c0,6.58,9.22,4.9,9.22,7.61c0,0.65-0.54,1.34-2.22,1.34c-1.3,0-3.33-0.42-5.01-1.87l-2.49,3.6c1.65,1.26,3.83,2.56,7.19,2.56
	c4.59,0,7-2.45,7-5.78C100.59,17.53,91.48,18.75,91.48,16.19z M120.88,20.28c0,0.5-0.04,1.03-0.11,1.49h-13.89
	c0.54,2.22,2.33,3.79,5.05,3.79c1.64,0,3.44-0.61,4.51-2.03l3.21,2.56c-1.65,2.33-4.55,3.71-7.69,3.71c-5.47,0-9.68-4.09-9.68-9.41
	c0-5.2,3.63-9.64,9.41-9.64C117.09,10.76,120.88,15.12,120.88,20.28z M116.14,18.29c-0.27-1.84-2.18-3.25-4.48-3.25
	c-2.18,0-3.94,1.3-4.63,3.25H116.14z M81.55,2.15c-1.68,0-2.98,1.26-2.98,2.95c0,1.68,1.3,2.98,2.98,2.98c1.68,0,2.95-1.3,2.95-2.98
	C84.5,3.41,83.24,2.15,81.55,2.15z M68.94,25.29c-2.75,0-5.01-2.3-5.01-5.01c0-2.72,2.26-5.01,5.01-5.01c1.72,0,3.21,0.84,4.13,2.14
	l4.06-2.33c-1.72-2.6-4.74-4.32-8.19-4.32c-5.36,0-9.64,4.21-9.64,9.53c0,5.24,4.28,9.53,9.64,9.53c3.48,0,6.58-1.8,8.26-4.48
	l-4.02-2.37C72.31,24.37,70.74,25.29,68.94,25.29z M79.18,29.31h4.71V11.1h-4.71V29.31z M31.71,13.01V11.1H27v18.21h4.71v-8.99
	c0-3.25,2.33-5.05,4.86-5.05c1.15,0,1.64,0.08,2.14,0.19v-4.67c-0.5-0.04-0.96-0.04-1.42-0.04C35.19,10.76,33.24,11.25,31.71,13.01z
	 M144.37,11.1l-4.55,12.59l-4.55-12.59h-5.2l7.54,18.71l-3.29,9.18h4.59L149,11.1H144.37z M24.74,20.28c0,5.26-4.26,9.53-9.53,9.53
	c-4.94,0-9-3.76-9.48-8.57v17.75H1V11.09h4.74v8.23c0.48-4.81,4.54-8.57,9.48-8.57C20.47,10.75,24.74,15.02,24.74,20.28z M20,20.28
	c0-2.64-2.14-4.79-4.79-4.79c-2.64,0-4.79,2.14-4.79,4.79c0,2.64,2.14,4.79,4.79,4.79C17.86,25.06,20,22.92,20,20.28z"
            fill="#ffffff"/>
    </svg>
    <img *ngIf="!!logoUrl" [src]="logoUrl" alt="" aria-hidden="true"/>

  </div>
  <div aria-hidden="true"
       class="loading-screen-spinner">
    <svg height="16"
         width="16">
      <path
        d="M8 16a7.928 7.928 0 0 1-3.428-.77l.857-1.807A6.006 6.006 0 0 0 14 8c0-3.309-2.691-6-6-6a6.006 6.006 0 0 0-5.422 8.572l-1.806.859A7.929 7.929 0 0 1 0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"
        fill="#fff"/>
    </svg>
  </div>
</div>
