import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';

import {Constants} from './constants';
import {Utils} from './utils';

export interface IUserInfo {
  displayName?: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  status: string;
  username?: string;
}
/**
 * @class ValidateTokenService service class to validate token
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class ValidateTokenService {

  token: string;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  /**
   * @function validateToken function to call backend service to validate the token
   * @see https://angular.io/guide/observables
   * @return Observable<any>
   */
  validateToken(): Observable<IUserInfo> {
    const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
      Constants.VALIDATE_TOKEN_URI;
    return this.http
      .post<IUserInfo>(endpoint, {token: this.token});
  }

}
