<footer class="py-3">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-xs-12 col-md-8 col-lg-6">
        <div
          class="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-sm-center">
          <a
            [class.footer-logo]="!logoUrl"
            [href]="logoAnchorUrl"
            class="d-block d-sm-inline-block text-center text-sm-left mr-sm-2"
            rel="noopener noreferrer"
            target="_blank"
          ><img *ngIf="logoUrl" [src]="logoUrl" alt="" aria-hidden="true" class="footer-logo"
          /></a>
          <span
            [innerHTML]="copyrightHolder"
            class="d-block d-sm-inline-block text-center text-sm-left pt-1 pb-3 p-sm-0"
          ></span>
        </div>
      </div>

      <!-- signin footer links -->
      <div class="col-xs-12 col-md-4 col-lg-6">
        <div class="d-flex justify-content-center justify-content-sm-end align-items-sm-center">
          <a class="d-block"
             href="https://www.precisely.com/legal">Legal</a>
          <a class="d-block mx-3"
             href="https://www.precisely.com/legal/privacy-policy">Privacy</a>
          <a class="d-block"
             href="https://www.precisely.com/legal/terms-of-use">Terms of use</a>
        </div>
      </div>
    </div>
  </div>
</footer>
